<template>
  <PracticePlayPage
    :image="category.image"
    :title="category.name[$i18n.locale]"
    :description="category.description[$i18n.locale]"
    :style="{ '--dialogScale': scale }"
  >
    <v-row justify="center" id="attach">
      <v-col
        class="ma-3 mb-10"
        cols="auto"
        v-for="l in languages"
        :key="l.locale"
      >
        <v-card shaped class="card" elevation="8">
          <div
            class="card__imageBox"
            :style="{
              background: `linear-gradient(45deg, ${l.gradientColor1}, ${l.gradientColor2})`
            }"
          >
            <v-img :src="calculatedImageId(l.locale)"> </v-img>
          </div>
          <v-card-title>
            {{ l.title }}
          </v-card-title>

          <div class="card__actionBox">
            <v-btn color="primary" rounded @click="showPrayer(l)">
              {{ $t('pray') }}
            </v-btn>
            <span class="ml-4 primary--text">{{ l.name }}</span>
          </div>
        </v-card>
      </v-col>
      <v-dialog
        v-if="selectedLang"
        fullsceen
        v-model="dialog"
        overlay-opacity="1"
        max-width="800"
        persistent
        attach="#attach"
        :style="{ transform: `scale(${scale})` }"
      >
        <v-card shaped>
          <v-row
            no-gutters
            justify="center"
            class="card__imageBox"
            :style="{
              background: `linear-gradient(45deg, ${selectedLang.gradientColor1}, ${selectedLang.gradientColor2})`
            }"
          >
            <v-col sm="7" cols="12">
              <Player
                v-if="playVideo"
                :media="{ type: 'youtube', playerId: '7PrUU2JVjTg' }"
                :emit="['ended', 'ready']"
                @ready="playPrayerVideo('playerRef-' + selectedLang.locale)"
                @ended="playVideo = false"
                :options="{
                  autoplay: false,
                  displayDuration: true,
                  noCookie: false,
                  rel: 0,
                  showinfo: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                  muted: false,
                  settings: ['captions'],
                  captions: { active: true }
                }"
                class=""
                :key="'prayer' + selectedLang.locale"
                :ref="'playerRef-' + selectedLang.locale"
              >
              </Player>
              <div v-else>
                <v-img
                  :src="calculatedImageId(selectedLang.locale)"
                  :class="{ clippedImage: $vuetify.breakpoint.smAndUp }"
                >
                </v-img>
              </div>
            </v-col>
            <v-col
              sm="5"
              cols="12"
              :style="{ textShadow: `1px 1px ${selectedLang.gradientColor1}` }"
              class="text-h4 text-center mt-2 mb-2 pl-5 pr-5 d-flex justify-center align-center card__title"
              :class="{
                'card__title-indian':
                  selectedLang.locale == 'gu' || selectedLang.locale == 'hi'
              }"
              v-if="$vuetify.breakpoint.smAndUp"
            >
              {{ selectedLang.title }}
            </v-col>
            <v-icon
              v-if="!playVideo"
              class="card__prayerVideo"
              x-large
              @click="playVideo = true"
              >mdi-play-circle</v-icon
            >
          </v-row>

          <div
            v-if="!$vuetify.breakpoint.smAndUp"
            class="text-h5 text-center mt-2 mb-2"
            :style="{ textShadow: `1px 1px ${selectedLang.gradientColor1}` }"
          >
            {{ selectedLang.title }}
          </div>
          <v-card-text
            class="mt-5 text-h6 text-sm-h5"
            :class="{
              prayerText: $vuetify.breakpoint.smAndUp,
              prayerTextMobile: !$vuetify.breakpoint.smAndUp
            }"
          >
            <p v-html="$i18n.messages[selectedLang.locale]['prayerText']"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div v-if="$vuetify.breakpoint.smAndUp" class="mr-3">
              <v-icon class="mr-3" @click="scaleUp()"
                >mdi-magnify-plus-outline</v-icon
              >
              <v-icon @click="scaleDown()">mdi-magnify-minus-outline</v-icon>
            </div>

            <v-btn
              color="primary"
              text
              @click="closeDialog('playerRef-' + selectedLang.locale)"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </PracticePlayPage>
</template>

<script>
import PracticePlayPage from '../components/common/PracticePlayPage.vue'
import Player from '../components/Player.vue'
import Utils from '../util/Utils'
export default {
  components: {
    PracticePlayPage,
    Player
  },
  computed: {
    category() {
      return this.$store.state.practicePlayCategories.find(
        (c) => c.id == 'prayer'
      )
    }
  },
  data: () => ({
    playVideo: false,
    sections: [],
    selectedLang: undefined,
    tab: null,
    dialog: false,
    scale: 1.0,
    maxScale: 1.5,
    minScale: 1.0,
    languages: [
      {
        name: 'ગુજરાતી',
        locale: 'gu',
        value: 'gujarati',
        gradientColor1: '#8dc145',
        gradientColor2: 'transparent',
        title: 'યાદશક્તિ વધારવા માટેની પ્રાર્થના..'
      },
      {
        name: 'English',
        locale: 'en',
        value: 'eng',
        gradientColor1: '#9a64c6',
        gradientColor2: 'transparent',
        title: 'Prayer to help improve memory power'
      },
      {
        name: 'हिन्दी',
        locale: 'hi',
        value: 'hindi',
        gradientColor1: '#599dd2',
        gradientColor2: 'transparent',
        title: 'याददाश्त बढ़ाने के लिए की जानेवाली प्रार्थना'
      },
      {
        name: 'Español',
        locale: 'es',
        value: 'es',
        gradientColor1: '#b88372',
        gradientColor2: 'transparent',
        title: 'Oración para incrementar el poder de lamemoria'
      },
      {
        name: 'Português',
        locale: 'pt',
        value: 'pt',
        gradientColor1: '#54c0a4',
        gradientColor2: 'transparent',
        title: 'Oração para aumentar o poder de memória'
      },
      {
        name: 'Deutsch',
        locale: 'de',
        value: 'de',
        gradientColor1: '#b34e54',
        gradientColor2: 'transparent',
        title: 'Gebet zur Stärkung der Gedächtniskraft'
      }
    ]
  }),
  methods: {
    calculatedImageId(url) {
      return Utils.imgUrlByPath(`prayer/${url}.png`)
    },

    showPrayer(lang) {
      this.selectedLang = lang
      this.playVideo = false
      this.scale = this.minScale
      this.dialog = true
    },
    playPrayerVideo(playerRef) {
      this.$refs[playerRef].player.play()
    },
    closeDialog(playerRef) {
      if (this.$refs[playerRef]) {
        this.$refs[playerRef].player.stop()
      }
      this.playVideo = false
      this.dialog = false
    },
    scaleUp() {
      if (this.scale + 0.1 <= this.maxScale) {
        this.scale += 0.1
      }
    },
    scaleDown() {
      if (this.scale - 0.1 >= this.minScale) {
        this.scale -= 0.1
      }
    }
  }
}
</script>
<style scoped>
/**.v-dialog reactive scaling using data prop */
::v-deep .v-dialog {
  transform: scale(v-bind('scale'));
}
</style>

<style lang="scss" scoped>
@import '../styles/global.scss';

::v-deep .v-card__title {
  font-weight: 600;
  word-break: break-word;
}

.clippedImage {
  background-size: cover;
  background-blend-mode: screen;
  -webkit-clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.prayerText {
  text-align: center;
  font-style: italic;
  color: black !important;
  font-size: 2rem !important;
  line-height: 3rem;
}
.prayerTextMobile {
  text-align: center;
  font-style: italic;
  color: black !important;
  font-size: 1.5rem !important;
  line-height: 2rem;
}
::v-deep .plyr {
  border-radius: 0px !important;
  border-top-left-radius: 25px !important;
}
.card {
  width: 300px;
  height: 320px;
  z-index: 0;
  transform-style: preserve-3d;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01) translateY(-10px);
  }

  &__imageBox {
    position: relative;
    .v-image {
      opacity: 0.8;
      border-top-left-radius: 25px;
    }
  }

  &__title {
    line-height: 3.5rem;
    font-size: 3.5rem !important;
    font-family: Rancho, Ekatra, serif !important;

    &-indian {
      line-height: 3rem;
      font-size: 2.5rem !important;
    }
  }

  &__actionBox {
    position: absolute;
    bottom: 5px;
    padding: 5px;
    padding-left: 10px;
  }

  &__prayerVideo {
    position: absolute;
    right: 15px;
    bottom: 15px;
    color: black;
    border-radius: 40px;
  }
}
</style>
